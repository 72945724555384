<template>
	<footer>
		<div class="page_footer">

			<div class="p_footer">
				<div class="p_container">
					<div class="p_box p_clearfix">
						<div class="p_box-table p_fl">
							<div class="p_box-table-title">
								<span>用户指南</span>
							</div>
							<ul>
								<li><a href="/intro/detail/1">云平台用户协议</a></li>
								<li><a href="javascript:alert('资料正在补充！')">云平台隐私政策</a></li>
								<li><a href="/intro/detail/2">购买须知</a></li>
								<li><a href="javascript:alert('资料正在补充！')">研学活动安全须知</a></li>
							</ul>
						</div>
						<div class="p_box-table p_fl" v-if="isMobile">
							<div class="p_box-table-title">
								<span>用户指南</span>
							</div>
							<ul>
								<li><a href="/intro/detail/3">教育主管部门用户操作手册</a></li>
								<li><a href="/intro/detail/4">学校用户操作手册</a></li>
								<li><a href="/intro/detail/5">研学基地用户操作手册</a></li>
								<li><a href="/intro/detail/6">研学机构用户操作手册</a></li>
								<li><a href="/intro/detail/7">学生/家长用户操作手册</a></li>
								<li><a href="/intro/detail/8">城市合伙人用户操作手册</a></li>
							</ul>
						</div>
						<div class="p_box-table p_fl">
							<div class="p_box-table-title">
								<span>文档下载</span>
							</div>
							<ul>
								<li><a href="javascript:alert('资料正在补充！')">承接单位报备样表</a></li>
								<li><a href="javascript:alert('资料正在补充！')">委托协议模板</a></li>
								<li><a href="javascript:alert('资料正在补充！')">研学活动报备表样表</a></li>
								<li><a href="javascript:alert('资料正在补充！')">与家长协议书模板</a></li>
							</ul>
						</div>
						<div class="p_box-table p_fl">
							<div class="p_box-table-title">
								<span>常见问题</span>
							</div>
							<ul>
								<li><a href="/intro/detail/9">学校如何信息完善</a></li>
								<li><a href="/intro/detail/10">学校如何组织活动</a></li>
								<li><a href="javascript:alert('资料正在补充！')">学生如何绑定班级身份</a></li>
								<li><a href="javascript:alert('资料正在补充！')">如何入驻合作</a></li>
							</ul>
						</div>
						<div class="p_box-wrap">

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page_footer">
			<div class="p_foot">
				<p>
					<a class="gov" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2023015371号</a>
					<a class="gov" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001955"
						target="_blank">
						<img style="width:.0729rem;height:.0729rem;" src="../assets/images/gongan.png" alt="">
						鲁公网安备 37011202001955号</a>
					<a class="gov" href="https://beian.miit.gov.cn/" target="_blank">广播电视节目制作经营许可证：(鲁)字第02478号</a>
				</p>
				<p>
					<a class="gov" href="javascript:void(0);"> Copyright © 2020-2023 黄河流域研学教育科技（山东）有限公司 版权所有</a>
				</p>
				<p>
					联系方式：0546-8550971 邮箱：HHLYYXJYKJ@126.com
				</p>
			</div>
		</div>
		<!-- <div class="bk_footer"></div> -->
	</footer>
</template>

<script>
import { getSelectStatus } from "@/service/api";
export default {
	name: "PageFooter",
	mounted() {
		this.getList();
	},
	data() {
		return {
			isMobile: true
		};
	},

	methods: {
		onlinkClick(url) {
			//_this.$message.warning("请输入手机号");
			if (url == '') {
				this.$message.warning("资料正在补充！");
			} else {
				this.$router.push(url);
			}
		},


		getList() {
			getSelectStatus().then(res => {
				let jsp = JSON.parse(res);
				if (jsp.site_show == 0) {
					this.isMobile = false;
				} else this.isMobile = true;
			});
		}
	}
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/const.scss";

footer {
	.page_footer {
		width: 100%;
		/*height:300px;*/
		background: $default_color;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.p_footer {
			overflow: hidden;
			/*background: #3e3e3e;*/
			display: flow-root;

			.p_container {
				width: 7.5rem;
				margin: 0 auto;
			}
		}

		.p_clearfix {
			clear: both;
		}

		.p_fl {
			float: left !important;
		}

		.p_box {
			display: flow-root;
			height: 340px;
			padding-top: 41px;
			box-sizing: border-box;

			.p_container {
				width: 1200px;
				margin: 0 auto;
			}
		}

		.p_box-table {
			display: flow-root;
			width: 25%;
			height: 100%;

			.p_box-table-title {
				margin: 0;
				padding: 0;
				box-sizing: border-box;

				span {
					font-size: 18px;
					font-family: Source Han Serif TC;
					font-weight: 700;
					color: #fff;
					line-height: 30px;
					/*opacity: .4;*/
				}
			}

			ul {
				display: block;
				list-style-type: disc;
				margin-block-start: 1em;
				margin-block-end: 1em;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
				padding-inline-start: 40px;

				li {
					list-style: none;
					font-size: 14px;
					font-family: Source Han Serif TC;
					font-weight: 400;
					color: #fff;
					line-height: 30px;
					/*opacity: .4;*/
					cursor: pointer;

					a {
						text-decoration: none;
						color: #fff;
					}

					a:hover {
						color: #40a9ff;
					}
				}

				span {
					font-size: 18px;
					font-family: Source Han Serif TC;
					font-weight: 700;
					color: #fff;
					line-height: 30px;
					opacity: .4;
				}
			}
		}

		.p_box-wrap {
			position: relative;
			bottom: 50px;
			display: inline-block;
			width: 100%;
		}

		.p_foot {
			width: 100%;
			/*height: 80px;*/
			padding: 5px 10px;
			background: #25600D;
			display: block;
			color: #fff;
			line-height: 38px;
			justify-content: center;

			p {
				display: block;
				width: 7.5rem;
				text-align: center;
				margin: 2px auto !important;
				line-height: 24px !important;
			}
		}

		&_content {
			width: $content-width;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: .4896rem;

			img {
				height: .2604rem;
			}

			div {
				flex: 1;
				text-align: center;
				font-size: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.info {}

				.gov {
					min-width: 100px;
					margin-top: .1042rem;
					color: #fff;
				}

				a {
					font-size: .0833rem;
				}
			}
		}
	}

	.bk_footer {
		// margin-top: .4688rem;
		height: .5208rem;
	}
}
</style>
